import React from 'react';
import { Link } from 'gatsby';

import image3 from '../assets/images/promise-2.png';
import image1 from '../assets/images/promise-3.png';
import image2 from '../assets/images/sign-up.png';
import { CustomButton } from '../components/custom-button';
import { IImageTextCardItem } from '../components/ImageTextCard';
import { ScPage } from '../components/page/styled';
import { MainLayout } from '../layouts/main-layout';
import { EmployeeNurseryHero } from '../sections/EmplooyeeNursery/EmployeeNurseryHero';
import { HowItWorks } from '../sections/EmplooyeeNursery/HowItWorks';
import { NurseryCalculatorSection } from '../sections/EmplooyeeNursery/NurseryCalculator';
import { InfoSection } from '../sections/EmployeeCycling/InfoSection';
import {InviteYourEmployer, Schema} from '../sections/EmployeeCycling/InviteYourEmployer';
import {SEO} from "../components/Seo/Seo";

const items: IImageTextCardItem[] = [
	{
		title: 'Invite</br> your employer',
		text: 'To use Gogeta Nursery your employer needs to sign up. It’s simple, quick and doesn’t cost them a penny.',
		image: image1,
	},
	{
		title: 'Sign up your nursery',
		text: 'You can use any OFSTED registered nursery, simply sign up and we’ll get them on board.',
		image: image2,
	},
	{
		title: 'Start</br> saving',
		text: 'Enjoy that extra slice of your salary by paying nursery fees out of your gross pay. Simple.',
		image: image3,
	},
];

const EmployeeNursery = () => {
	return (
		<MainLayout>
			<ScPage>
				<EmployeeNurseryHero />
				<NurseryCalculatorSection isItEmployee={true} />
				<HowItWorks items={items} />
				<InfoSection
					title="FAQs"
					text="Got more questions? We’ve got you covered."
					buttons={
						<Link to={'/employee-faq#nursery-faqs/'}>
							<CustomButton>Get some answers</CustomButton>
						</Link>
					}
				/>
				<InviteYourEmployer  schema={Schema.NURSERY} />
				<InfoSection
					title="There’s more where that came from"
					text="With Gogeta you can also save on bike fees."
					buttons={
						<Link to="/employee-cycling/">
							<CustomButton>Help me save on cycling</CustomButton>
						</Link>
					}
				/>
			</ScPage>
		</MainLayout>
	);
};

export default EmployeeNursery;

export const Head = () => (
	<SEO
		title="Benefits of Nursery Schemes | Save on Childcare with Gogeta"
		description="Discover how Gogeta’s nursery scheme helps employees save on childcare costs through salary sacrifice, making childcare more affordable and accessible."
	/>
)
